@import 'Styles/colors';
@import 'Styles/mixins';

.loaderContainer {
  position: relative;
  margin: auto;
}

.innerContainer {
  @include font-small;
  color: font-color(light);

  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.initializing {
  opacity: 0;
  animation-name: showText;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes showText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
