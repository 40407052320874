@import 'Styles/mixins';
@import 'Styles/colors';

.formField {
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background-color: palette(lowlight, 900);
  padding: 11px 25px;

  .label {
    @include font-body;
    color: font-color(light);

    margin: 0 40px 0 30px;
  }

  > div:not(:last-child) {
    flex: 1 1 0px;
    min-width: 210px;
    margin-right: 2vw;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
}

.customLabel {
  @include font-button;

  text-transform: uppercase;
  display: flex;

  span {
    flex-shrink: 0;
  }

  .customLabelHighlight {
    @include font-small;

    text-transform: capitalize;
    margin-left: $grid-unit;

    &.highlight {
      color: palette(highlight);
    }
    &.feedback {
      color: palette(feedback);
    }
    &.success {
      color: palette(success);
    }
    &.regular {
      color: font-color(regular);
    }
  }
}
