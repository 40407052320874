@import 'Styles/colors';
@import 'Styles/mixins';
@import 'Styles/shadows';

.notificationsContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: $z-index-notifications;
  pointer-events: none;

  .container:last-child {
    margin-bottom: 0;
  }
}

.container {
  @include shadow(4);

  pointer-events: all;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  max-width: 1000px;
  margin: 0 auto;
  height: 60px;
  max-height: 0;
  margin-bottom: 3px;
  padding: 0 20px;
  overflow: hidden;
  background-color: palette(base, 400);

  transition: max-height ease 0.3s;

  span,
  svg {
    color: font-color(light);
    font-size: 13px;
    transition: font-size ease 0.3s;

    &:hover {
      color: white;
      font-size: 13.2px;
    }
  }

  .typeLabel {
    @include font-button;
    color: font-color(dark);

    height: 3 * $grid-unit;
    line-height: 3 * $grid-unit;
    border-radius: 1.5 * $grid-unit;
    padding: 0 3 * $grid-unit;
  }

  &.MESSAGE {
    .typeLabel {
      background-color: palette(highlight);
    }
  }

  &.ERROR {
    .typeLabel {
      background-color: palette(error);
    }
  }
}

.notificationsContainer .container:first-of-type {
  border-radius: 12px 12px 0 0;
}

.message {
  @include font-body;
  color: font-color(light);

  padding: 0 20px;
  margin-right: auto;
  overflow: hidden;
}
