@import 'Styles/mixins';
@import 'Styles/colors';
@import 'Styles/shadows';

.container {
  @include shadow(4);

  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: $bg-box;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 13px 18px;
  transition: all ease 2s;

  p {
    margin: 0;
  }
}

.title {
  @include font-body;
  color: font-color(light);
}

.infoNumber {
  color: font-color(highlight);
  font-weight: 400;

  &.regular {
    @include font-title;
  }
  &.big {
    @include font-headline;
  }
}

.expandIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
}

.help {
  position: absolute;
  top: 16px;
  right: 58px;
  z-index: 1;
}

.modalContainer {
  @include font-small;
  font-weight: 500;

  position: absolute;
  background-color: palette(base, 400);
  z-index: 1;
  transform: translate(-100%, -50%);
  border-radius: 0.5 * $grid-unit;

  &.visible {
    @include shadow(4);

    &:after {
      content: '';
      border: solid 6px palette(base, 400);
      border-left-color: transparent;
      border-bottom-color: transparent;
      transform: rotate(45deg);
      position: absolute;
      right: -5px;
      top: calc(50% - 7px);
    }

    padding: 1.5 * $grid-unit 2 * $grid-unit;
    width: 45 * $grid-unit;
  }

  .modal {
    position: absolute;
    top: 0;
  }
}
