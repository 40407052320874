@import 'Styles/colors';
@import 'Styles/mixins';

$color-active: palette(highlight);
$color-inactive: palette(lowlight);

.container {
  color: font-color(lowlight);

  overflow: visible;
  pointer-events: none;
}

.container,
.hexContent,
.info {
  display: flex;
  flex-direction: column;
}
.container,
.hexContent {
  position: relative;
  justify-content: space-between;
  text-align: center;
  padding: 20px 0 8px 0;
}

.hexContent {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin-left: 5px;
  pointer-events: all;
  clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  cursor: pointer;
  align-items: center;
}

.bg {
  position: absolute;
  top: -60px;
  left: -43px;
  pointer-events: none;
}

.statusLabel {
  position: absolute;
  width: 69%;
  left: 50%;
  height: 7%;
  background-color: $color-black;
  z-index: 9;
}

.bgOverlap {
  @extend .statusLabel;

  top: 43%;
  transform: translateX(-50%) perspective(47px) rotateX(13deg);

  &.CREATING {
    background-color: $color-inactive;
  }
  &.STARTED {
    background-color: $color-primary;
  }
  &.ERROR {
    background-color: $color-error;
  }
}
.bgVersionOverlap {
  @extend .statusLabel;

  top: 50.5%;
  transform: translateX(-50%) perspective(47px) rotateX(-13deg);
  background-color: $color-inactive;

  &.STARTED,
  &.STOPPED {
    background-color: $color-inactive;
  }

  &.PUBLISHED {
    background-color: $color-ok;
  }
}

.statusText {
  @include font-button;
  line-height: 24px;
  color: font-color(dark);

  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 9;
}

.bgOverlapText {
  @extend .statusText;

  color: font-color(lowlight);

  top: 43.5%;
}
.bgOverlapVersionText {
  @extend .statusText;

  top: 50.4%;
}

.container span,
.title {
  z-index: 1;
}

.info span {
  @include font-small;
}

.status {
  margin-bottom: 8px;
}

.title {
  @include font-body;

  width: 130px;
}

.info {
  margin-top: auto;
}

.info_type {
  @include font-small;

  opacity: 0.5;
  margin-bottom: 2px;
}

.container.active {
  color: $color-active;

  .info_type {
    color: $color-active;
    opacity: 0.35;
  }
}

.container.hovered {
  z-index: 3;

  .hexContent {
    @extend .active;
    z-index: 2;
  }
}

.container.disabled > .hexContent {
  pointer-events: none;
}

.empty {
  svg path {
    fill: transparent;
    stroke: font-color(regular);
    stroke-dasharray: 5 5;
    stroke-width: 1px;
    transition: stroke ease 0.3s;
  }

  .hexagonBorderText {
    @include font-button;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: color ease 0.3s;
  }

  &.hovered {
    svg path {
      stroke: font-color(light);
    }
    .hexagonBorderText {
      color: font-color(light);
    }
  }
}
