@import 'Styles/variables';
@import 'Styles/mixins';
@import 'Styles/shadows';
@import 'Styles/colors';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;

  .address {
    align-self: center;
  }
}
