@import 'Styles/colors';
@import 'Styles/mixins';
@import 'Styles/variables';

$color-tabs-bg: palette(base, 700);
$color-tab-bg: palette(base);
$color-tab-selected-bg: palette(lowlight, 800);

.tabContainer {
  height: $grid-unit * 5;
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  padding: $grid-unit $grid-unit 0 $grid-unit;
  background-color: $color-tabs-bg;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 2px;
  }
}
.tab {
  @include font-small;
  color: font-color(grey);

  min-width: 144px;
  max-width: 280px;

  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $grid-unit $grid-unit $grid-unit $grid-unit * 2;
  height: $grid-unit * 4;
  background-color: $bg-color;
  transition: background-color ease 0.3s, color ease 0.3s;

  .icon {
    cursor: pointer;
    margin-left: $grid-unit;
  }

  .runtime,
  .separator,
  .version {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .separator {
    margin: 0 $grid-unit;
  }

  .version.openedVersion {
    color: palette(highlight, 800);
  }
  &:hover .version.openedVersion {
    color: palette(highlight, 700);
  }
  &.selected .version.openedVersion {
    color: palette(highlight);
  }

  &:hover {
    background-color: palette(base, 400);
    color: font-color(lowlight);
  }
  &.selected {
    color: font-color(light);
    height: $grid-unit * 4;
    background-color: $color-tab-selected-bg;
    cursor: default;
  }
  &:not(:last-child) {
    margin-right: $grid-unit;
  }
}
