@import 'Styles/colors';
@import 'Styles/mixins';
@import 'Styles/variables';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0 8px 0 13px;
  width: 100%;
  height: $header-height;
  background-color: $bg-color-dark;
  white-space: nowrap;
  z-index: $z-index-header;
}

.konstellationIcon {
  height: 21px;
  margin: 0 7px 0 9px;
}
.konstellationText {
  height: 40px;
  margin-right: 20px;
}

.customHeaderElements {
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  align-items: center;

  flex-wrap: wrap;
  overflow: hidden;
}

.splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $bg-color-dark;
  z-index: 100;
}

.linkDisabled {
  pointer-events: none;
}
