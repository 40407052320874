@import 'Styles/variables';
@import 'Styles/colors';
@import 'Styles/funcs';

.circle {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: $color-ok;
  flex-shrink: 0;

  @each $state in $version-statuses {
    &.#{$state} {
      background-color: get-color-prop($state);
    }
  }
}
