@import 'Styles/colors';
@import 'Styles/mixins';
@import 'Styles/variables';

$input-height: 30px;

.container {
  @include font-body;
  line-height: 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: auto;
  padding: 0 20px;
  flex-shrink: 0;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    margin-right: $grid-unit;
  }
}
.rightFilters {
  flex-shrink: 0;

  .filterLevel {
    width: 142px;
  }

  .dateFilter {
    width: $grid-unit * 21.5;
  }
}

.leftContainer {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100 * $grid-unit;

  .searchFilter {
    width: 60%;
    height: $input-height;
    min-width: 100px;
    margin: $grid-unit $grid-unit $grid-unit 0;

    .searchForm {
      input {
        height: $input-height;
        min-height: $input-height;
      }
      & > div {
        min-height: $input-height;
        height: $input-height;
      }
    }
  }

  .selectProcesses {
    width: 40%;
    height: $input-height;
    min-width: 170px;

    .selectProcessForm > div:first-child {
      height: $input-height;
      min-height: $input-height;
      line-height: $input-height - 1px;
    }
  }
}
