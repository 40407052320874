@import '~kwc/styles/variables';

$container-max-width-login: 960px;
$container-max-width-add-element: 960px;

$header-height: 40px;
$runtime-menu-width: 70px;
$lateral-menu-width: 253px;

$height-log-item: 24px;

$version-statuses: 'PUBLISHED', 'CREATING', 'CREATED', 'STARTED', 'STOPPED',
  'STARTING', 'STOPPING', 'ERROR';

$logs-height: 22px;

$z-index-modal: 99999;
$z-index-notifications: 99999;
$z-index-logs: 1000;
$z-index-header: 10;

$status-error: ERROR;
$status-up: UP;
$status-ready: READY;
$status-error: ERROR;
$status-down: DOWN;
$status-loading: LOADING;
