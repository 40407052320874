@import 'Styles/colors';
@import 'Styles/mixins';

.wrapper {
  color: font-color(light);
  display: flex;
  flex-direction: row;
  background-color: #26292c;
  align-items: center;
  margin-top: auto;

  &.quickActions {
    background-color: transparent;
  }

  .item {
    @include font-body;
    font-weight: 600;

    height: 48px;
    padding-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;

    .icon {
      margin-right: 8px;
      line-height: 0;
    }

    &:hover {
      color: font-color(light);
    }
  }
}

.disabled {
  cursor: default;
  pointer-events: none;
  color: font-color(regular);
}

.warning {
  @include font-small;

  line-height: 2.5 * $grid-unit;
  margin-bottom: $grid-unit;

  .publishedVersion {
    text-transform: uppercase;
    color: font-color(light);
  }
}
