@import 'Styles/colors';
@import 'Styles/mixins';

.sections {
  @include font-small;
  font-weight: 600;
  color: font-color(lowlight);

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .section {
    width: 20%;
    padding: 11px;

    &:first-child {
      width: 40%;
    }

    &:not(:last-child) {
      border-right: 1px solid $bg-color-dark;
    }

    &:not(:first-child) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;

      p {
        margin-bottom: 8px;
      }
    }
  }
}

.accuracy {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.totalAccuracy {
  color: $color-ok;

  position: relative;
  width: 40%;
  margin-right: 30px;
  height: 100%;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 360%;
    font-weight: 400;
  }
}

.tempBox {
  width: 40%;
  margin-right: 30px;
  height: 100%;
  background-color: $color-black;
  opacity: 0.2;
}

.infoRows {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.infoRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  p:first-child {
    margin-right: 8px;
  }
}
