@import 'Styles/colors';
@import 'Styles/mixins';
@import 'Styles/variables';

$color-border: palette(base, 400);
$color-bg: palette(base, 700);
$color-header-bg: palette(base, 500);
$color-header-border: palette(base, 300);

.listWrapper {
  width: 100%;
  flex-grow: 1;
  position: relative;
}

.listContainer {
  height: 100%;
  background-color: palette(base);
}

.listContainer > div {
  border-bottom: solid 1px $color-border;
}

.container {
  @include font-body;
  line-height: 23px;

  height: $height-log-item;
  background-color: $color-bg;
  border-bottom: solid 1px palette(base, 400);
  transition: background-color ease 0.1s;

  &.loadPreviousLogs {
    display: flex;
    align-items: center;
    padding-left: $grid-unit * 2;
    cursor: pointer;
  }

  &.opened {
    height: auto;

    .expand {
      transform: scaleY(-1);
    }
  }

  transition: filter 0.15s ease;
  &:hover {
    filter: contrast(0.9) brightness(1.3);

    .expand {
      opacity: 1;
    }
  }

  &.DEBUG {
    background-color: palette(lowlight, 900);
  }

  &.WARN {
    background-color: palette(alert, 900);
  }

  &.ERROR {
    background-color: palette(error, 900);
  }
}

.row1 {
  @include font-code;

  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    flex-shrink: 0;
  }

  .icon {
    padding: 0 8px;
    text-align: center;
    width: 39px;
    font-weight: 500;
  }
}

.date {
  color: font-color(lowlight);
  width: 100px;
}

.hour,
.node,
.workflow {
  margin-left: 0 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.hour {
  color: font-color(lowlight);
  width: 116px;
}
.node {
  width: 160px;
  padding: 0 2 * $grid-unit;
}
.workflow {
  width: 130px;
}

.date,
.hour,
.workflow,
.node,
.message {
  padding: 0 $grid-unit;
}

.nodeName {
  color: font-color(light);
  font-weight: 500;
  margin-right: $grid-unit * 2;
}

.message {
  color: font-color(lowlight);

  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: initial !important;
}

.expand {
  height: $height-log-item;
  width: $height-log-item;
  line-height: 30px;
  text-align: center;

  transform: rotateX(0);
  transition: transform ease 0.4s, margin-top ease 0.4s, opacity ease 0.4s;
  opacity: 0;
  cursor: pointer;

  &:hover {
    color: font-color(light);
  }
}

.messageComplete {
  @include font-code;
  color: font-color(lowlight);

  padding: 10px 47px 30px 47px;

  pre {
    white-space: pre-wrap;
  }
}

.container.header {
  height: 3 * $grid-unit;
  border-bottom: solid 2px $color-header-border;
  pointer-events: none;
  background-color: $color-header-bg;

  .row1 {
    > div:not(:last-child) {
      border-right: 1px solid palette(base, 300);
    }

    div {
      @include font-small;
      color: font-color(lowlight);
      padding-left: $grid-unit;
    }

    .icon {
      width: 39px;
      height: 100%;
    }
  }
}

.clipboardInput {
  position: absolute;
  opacity: 0;
}

.loadMoreSpinner {
  position: relative;
  height: $height-log-item;
  background-color: $color-bg;
  border-bottom: solid 1px palette(base, 400);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    position: relative;
    height: 24px;
    width: 80px;
  }
}
