.container {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow: hidden;
  transition: padding ease 0.4s;
}

.content {
  position: relative;
  width: 100%;
  overflow: hidden;
}
