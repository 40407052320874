@import 'Styles/variables';
@import 'Styles/colors';
@import 'Styles/mixins';

.container {
  @include font-small;

  display: flex;
  height: 5 * $grid-unit;
  align-items: center;
  background-color: palette(base, 700);
  padding: 0 $grid-unit;

  .right {
    color: font-color(lowlight);
  }
}
