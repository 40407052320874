@import 'Styles/variables';
@import 'Styles/colors';
@import 'Styles/mixins';

.container {
  @include font-small;

  color: font-color(dark);
  background-color: palette(lowlight, 100);
  padding: 3px $grid-unit;
  border-radius: calc($grid-unit / 2);
  margin-right: 1.5 * $grid-unit;

  &.WARNING {
    background-color: palette(alert);
  }
  &.INFO {
    background-color: palette(feedback);
  }
}
