@import 'Styles/colors';
@import 'Styles/mixins';

$color-bar: map-get($color-dashboard, primary-darker);

.bar {
  pointer-events: none;
  fill: $color-bar;
}

.bgBar {
  fill: $color-warn;
  pointer-events: none;
}

.g {
  rect {
    transition: fill-opacity ease 0.4s;
  }

  g.legend {
    text {
      fill: font-color(light);
      dominant-baseline: central;
    }

    rect {
      rx: 10px;
    }

    & > g:nth-child(1) > rect {
      fill: $color-bar;
    }
    & > g:nth-child(2) > rect {
      fill: $color-warn;
    }
  }

  .yAxis line {
    stroke-opacity: 0.5;
  }

  .barLabel {
    font-size: 12px;
    text-anchor: middle;
    dominant-baseline: central;

    &.light {
      fill: $color-warn;
    }
    &.dark {
      fill: $color-bar;
    }
  }
}
