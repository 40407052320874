@import 'Styles/colors';
@import 'Styles/variables';
@import 'Styles/mixins';

$bg-color: palette(lowlight, 900);
$min-height: 60px;

.container {
  min-height: $min-height;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $bg-color;
  padding: 2 * $grid-unit 0 6px 0;

  .field {
    @include font-body;
    color: font-color(light);

    margin: 0 4% 0 2%;
  }

  .input {
    width: 40%;
  }

  .button {
    margin-left: 2%;
  }

  & *:first-child {
    margin-left: auto;
  }

  & *:last-child {
    margin-right: auto;
  }
}
