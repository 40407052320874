@import './colors';
@import './mixins';
@import './shadows';

$bg-tooltip: map-get($color-dashboard, tooltip);

.container {
  @include shadow(4);
  @include font-body;
  color: font-color(light);

  position: absolute;
  max-width: 184px;
  padding: 32px 8px;
  background-color: $bg-tooltip;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity ease 0.4s, top ease 0.4s, left ease 0.4s;

  .content {
    padding: 0 $grid-unit;
  }

  .title {
    margin-bottom: $grid-unit * 2;
    font-size: 13px;
  }

  &::after {
    content: '';
    position: absolute;
    border: solid 10px $bg-tooltip;
    transform: rotate(135deg);
    top: calc(100% - 13px);
    left: calc(50% - 10px);
  }

  &.down::after {
    transform: rotate(315deg);
    top: -5px;
    left: calc(50% - 10px);
  }

  &.left::after {
    transform: rotate(45deg);
    top: calc(50% - 10px);
    left: calc(100% + -16px);
  }

  &.down.left::after {
    transform: rotate(-2deg);
    top: -6px;
    left: calc(100% + -16px);
  }
}
