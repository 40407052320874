@import 'Styles/colors';
@import 'Styles/mixins';
@import 'Styles/shadows';
@import 'Styles/variables';

$color-bg: palette(base, 600);

.container {
  @include shadow(3);

  width: $lateral-menu-width;
  height: 100%;
  flex-shrink: 0;
  background-color: $color-bg;
  z-index: 1;
}

.header {
  width: 100%;
}

.back {
  @include font-subheader;
  color: font-color(light);

  padding: 17px 26px 20px 17px;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  svg {
    margin-right: 18px;
    color: font-color(regular);
    transition: color ease 0.4s;
  }

  &:hover {
    font-weight: bold;

    svg {
      color: white;
    }
  }
}

.navButtons {
  margin-top: 30px;
}
