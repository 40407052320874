@import 'Styles/mixins';
@import 'Styles/colors';

$color-bg: palette(base, 800);
$color-doc-bg: palette(base, 500);
$color-line: palette(base, 400);
$color-code-bg: palette(base, 400);

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: $color-bg;
}

.loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $color-bg;
}

.article {
  padding: 40px 64px 0 64px;
  overflow: scroll;
  width: 100%;
  background-color: $color-doc-bg;

  h2,
  h3,
  h4,
  h5,
  h6 {
    border-bottom: 1px solid $color-line;
  }

  h1 {
    @include font-mega;
    color: font-color(light);
  }

  h2 {
    @include font-jumbo;
    color: font-color(light);
  }

  h3 {
    @include font-display;
    color: font-color(light);
  }

  h4 {
    @include font-headline;
    color: font-color(light);
  }

  h5 {
    @include font-title;
    color: font-color(light);
  }

  h6 {
    @include font-subheader;
    color: font-color(light);
  }

  p {
    @include font-body;
    line-height: 28px;

    color: font-color(lowlight);
  }

  li {
    @include font-body;
    color: font-color(lowlight);
    padding-left: $grid-unit;
  }

  blockquote {
    margin: 0;
    padding: 1px 22px;
    border-left: solid 4px palette(lowlight, 200);
  }

  a {
    @include font-button;
    color: font-color(light);
    text-transform: uppercase;
  }

  pre {
    background-color: $color-code-bg !important;
    padding: 9px !important;
    border-radius: 5px;

    code {
      padding: 0;
    }
  }
  code {
    @include font-code;

    color: font-color(lowlight);
    background-color: $color-code-bg;
    border-radius: 5px;
    padding: 1px 9px;
  }
  table {
    border-spacing: 0px;

    thead tr th {
      border-top: 1px solid palette(lowlight);
      border-bottom: 1px solid palette(lowlight);
    }

    tr td:first-child,
    tr th:first-child {
      border-left: 1px solid palette(lowlight);
    }

    td,
    th {
      padding: 4px 12px;
      border-right: 1px solid palette(lowlight);
    }
    td {
      @include font-body;
      color: font-color(lowlight);
      border-bottom: 1px solid palette(lowlight);
    }
    th {
      color: font-color(light);
    }

    tbody tr:nth-child(odd) {
      background-color: palette(lowlight, 900);
    }
  }

  table {
    padding: 12px 0;
  }

  hr {
    height: 1px;
    color: $color-line;
    background-color: $color-line;
    border: none;
  }
}
.navigation {
  flex-shrink: 0;
  width: 217px;
  border-right: 1px solid $bg-color-dark;
}
