@import 'Styles/variables';
@import 'Styles/colors';
@import 'Styles/mixins';

.bg {
  width: 100%;
  height: 100%;
  background-color: $bg-color-dark;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(56px, 1fr));
  grid-gap: 24px;
  width: 100%;
  height: 100%;
  max-width: $container-max-width-login;
  margin: 0 auto;
}

.container {
  grid-area: 1 / 3 / 2 / 11;
  height: 100%;
  padding: $form-padding-height 0;
  background-color: $bg-color;

  h1 {
    margin-bottom: 30px;
  }
}

.subtitle {
  @include font-body;

  text-align: center;
  margin: 50px $form-padding-width;
  color: font-color(light);
}

.emailIcon {
  color: $color-ok;
}
