@import 'Styles/colors';
@import 'Styles/mixins';
@import 'Styles/funcs';
@import 'Styles/variables';

.wrapper {
  color: font-color(light);
  padding: 16px 0;
  padding-left: 16px;
  border-bottom: solid 1px palette(base, 900);
}

.name {
  @include font-body;
  color: font-color(light);

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  padding: 0 16px 16px 0;
  justify-content: space-between;
}

.desc {
  @include font-body;
  font-weight: 500;
  color: font-color(lowlight);

  padding-right: 30px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    @include font-button;
    text-transform: capitalize;
    font-weight: 500;
    color: $bg-color;
    text-align: center;
    line-height: 40px;
    height: 40px;

    @each $state in $version-statuses {
      &.#{$state} {
        color: get-color-prop($state);
      }
    }
  }
}
