@import 'Styles/colors';
@import 'Styles/mixins';

$color-primary-light: map-get($color-dashboard, primary-light);
$color-bar: map-get($color-dashboard, primary-dark);
$color-bar-dark: map-get($color-dashboard, primary-darker);
$color-guide: map-get($color-dashboard, guide-line);

.g {
  text,
  path,
  line,
  rect {
    pointer-events: none;
  }

  .yAxis text {
    transition: fill ease 0.5s;

    &.highlighted {
      fill: font-color(light);
    }
  }

  .guideLine {
    stroke: $color-guide;
    stroke-width: 2px;
  }
  .innerGuideLine {
    stroke: $color-bar;
    stroke-dasharray: 3 3;
    stroke-opacity: 0.33;
  }

  .seriesAxis {
    > path {
      display: none;
    }

    & > g:nth-child(2) > line {
      display: none;
    }
    line {
      @extend .innerGuideLine;
    }
  }

  .seriesLabel {
    @include font-body;

    fill: font-color(light);
  }

  .rowBg {
    pointer-events: all;
  }

  .bar {
    fill: $color-bar;
    transition: fill ease 0.4s;

    &.unhighlighted {
      fill: $color-bar-dark;
    }
    &.highlighted {
      fill: $color-primary-light;
    }
  }

  .bgBar {
    fill: $color-warn;
  }

  .barValue {
    dominant-baseline: central;
    fill: $color-primary;
    font-weight: 600;
    fill-opacity: 0;
    transition: fill-opacity ease 0.4s;

    &.highlighted {
      fill-opacity: 1;
    }
  }

  &.viewAllData {
    .barValue {
      fill-opacity: 1;
    }
  }

  .bars,
  .bar,
  .series,
  .serieG,
  .serie,
  .seriesAxis,
  .rowBgG,
  .rowBg,
  .axes,
  .xAxis,
  .yAxis {
    display: inherit;
  }
}
