@import 'Styles/variables';
@import 'Styles/colors';
@import 'Styles/mixins';

$color-wrapper-bg: palette(base, 500);

.wrapper {
  flex-shrink: 0;
  flex-direction: column;
  position: relative;
  width: $lateral-menu-width;
  background-color: $color-wrapper-bg;
  display: flex;
}
.runtimeNameSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lateralPanel {
  position: fixed;
  z-index: 3;
  left: $lateral-menu-width + $runtime-menu-width;
  top: $header-height;
  height: 100%;
  transition: width ease 0.3s;
  width: 0;
  overflow: hidden;

  &.opened {
    box-shadow: 13px 8px 20px -15px rgba(0, 0, 0, 0.75);
    width: 480px;
  }
}

.shield {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($color-shield, $opacity-shield);
}
