@import 'Styles/colors';

.container {
  display: flex;
  flex-direction: row;
  background-color: palette(base, 900);

  div {
    line-height: 33px !important;
  }
}

.right {
  display: flex;
  flex-direction: row;
}

.active {
  color: font-color(light);
}
