@import 'Styles/colors';
@import 'Styles/mixins';

$color-active: palette(lowlight, 900);
$color-hover: palette(lowlight, 700);

.item {
  @include font-button;
  font-weight: 700;
  color: font-color(lowlight);

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  height: $grid-unit * 7;

  transition: background-color ease 0.4s, color ease 0.4s;

  .icon {
    margin-right: 16px;
  }

  .iconWarning {
    margin-left: auto;
    color: $color-warn;
  }

  &:hover {
    color: font-color(light);
    background-color: $color-hover;
  }
}

.active {
  cursor: default;
  pointer-events: none;
  color: font-color(light);

  .item {
    background-color: $color-active;
    color: font-color(light);
  }
}
