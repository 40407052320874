@import 'Styles/variables';
@import 'Styles/mixins';
@import 'Styles/shadows';
@import 'Styles/colors';

$padding-y: 14px;
$separation: 21px;
$color-line: palette(base, 800);
$color-bg: $bg-color-dark;

.wrapper {
  position: fixed;
  top: 0;
  left: 0;

  opacity: 0;
  pointer-events: none;

  transition: top ease 0.2s, left ease 0.2s, opacity ease 0.4s;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
}
.container {
  @include shadow(2);

  border-radius: calc($grid-unit / 2);
  min-width: $grid-unit * 55;
  max-width: $grid-unit * 75;
  padding: 11px $padding-y;
  background-color: $color-bg;
  border-left: solid 3px $color-ok;
}

.separator {
  pointer-events: none;
  margin: auto;
  height: $separation;
  width: 2px;
  border-left: dashed 1px $color-line;
}

.header {
  display: flex;
  flex-direction: row;
  padding-bottom: $padding-y;
  border-bottom: solid 1px palette(base, 400);

  .title {
    @include font-button;
    line-height: 17px;

    margin-left: $padding-y;
  }
}

.content {
  padding: $padding-y 0;
}

.wrapper {
  &.#{$status-down},
  &.#{$status-loading} {
    .container {
      border-left-color: $color-status-down;
    }
  }

  &.#{$status-up} {
    .container {
      border-left-color: $color-status-up;
    }
  }

  &.#{$status-error} {
    .container {
      border-left-color: $color-status-error;
    }
  }
}
