@import 'Styles/mixins';
@import 'Styles/variables';
@import 'Styles/funcs';
@import 'Styles/colors';

$color-border: palette(base, 400);

.container {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px $color-border;
  transition: background-color ease 0.3s;
  color: font-color(light);

  &.even {
    background-color: $bg-color-lighter;
  }

  .creationDate,
  .name,
  .version,
  .statusLine,
  .statusCircle,
  .description {
    transition: color ease 0.3s, border ease 0.3s, background-color ease 0.3s;
  }

  &:hover {
    background-color: $color-black;
    cursor: pointer;

    .creationDate,
    .name,
    .version,
    .description {
      color: font-color(light);
    }

    .statusLine {
      border-bottom-color: $color-ok;
      border-top-color: $color-ok;
    }

    .statusCircle {
      background-color: $color-ok;
    }
  }

  p {
    margin: 0;
  }
}

.col1 {
  padding: 2.5 * $grid-unit 2 * $grid-unit 2.5 * $grid-unit 3 * $grid-unit;
  flex-shrink: 0;
  min-width: 45 * $grid-unit;
  display: flex;
  flex-direction: row;

  .labelContainer {
    .label {
      @include font-button;
      color: $bg-color;
      text-align: center;
      line-height: 24px;

      width: 96px;
      height: 24px;
      margin-right: 24px;

      @each $state in $version-statuses {
        &.#{$state} {
          background-color: get-color-prop($state);
        }
      }
    }
  }

  .actionsContainer {
    width: -webkit-fill-available;
  }

  .creation {
    @include font-caption;
    font-weight: 700;
    line-height: 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    margin-left: auto;
    width: fit-content;
    margin-bottom: 20px;
  }

  .creationDate {
    margin-left: 5px;
  }

  .actionButtons {
    width: fit-content;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
      margin-left: auto;
    }
  }
}

.col2 {
  padding: 2.5 * $grid-unit 2.5 * $grid-unit 2.5 * $grid-unit 4 * $grid-unit;
  border-left: solid 1px $color-border;
  width: 100%;

  .name {
    @include font-body;
    font-weight: 600;
    line-height: 17px;

    margin-bottom: 6px;
  }

  .version {
    @include font-subheader;
    line-height: 24px;

    margin-bottom: 15px;
  }

  .infoFields {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .descriptionField {
    flex-grow: 1;
    min-width: 40 * $grid-unit;
  }

  .publishedField {
    flex-grow: 2;
  }

  .infoField {
    margin: 0 3 * $grid-unit $grid-unit 0;
    flex-basis: 0;
  }

  .infoFieldTitle {
    @include font-caption;
    color: font-color(regular);

    margin-bottom: $grid-unit;
  }

  .infoFieldBody {
    min-height: 3 * $grid-unit;
  }

  .descriptionTitle,
  .activatedTitle {
    @include font-caption;
    color: font-color(regular);

    margin-bottom: 8px;
  }

  .activatedTitle {
    margin-top: 15px;
  }

  .col2CreatorName,
  .col2CreationDate {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .description {
    @include font-small;
    margin-bottom: 8px;
  }

  .versionError {
    color: palette(error);
  }

  .activatedContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .activatedAuthor,
  .activatedDate {
    @include font-caption;
    line-height: 17px;

    margin: 0 8px 0 8px;
  }
}
