$gap: 14px;
$hex-width: 264.32px;
$hex-height: calc($hex-width / 1.1547005);

:root {
  --Nhexa: 4;
}
@media only screen and (min-width: 1363px) {
  :root {
    --Nhexa: 6;
  }
}
@media only screen and (min-width: 1766px) {
  :root {
    --Nhexa: 8;
  }
}
@media only screen and (min-width: 2169px) {
  :root {
    --Nhexa: 10;
  }
}
@media only screen and (min-width: 2572px) {
  :root {
    --Nhexa: 12;
  }
}
@media only screen and (max-width: 960px) {
  :root {
    --Nhexa: 2;
  }
}

.container {
  margin-top: 16px;
  width: 0;
  display: grid;
  grid-area: hexagons;
  grid-template-columns: repeat(var(--Nhexa), 1fr);
  grid-gap: $gap;
  grid-auto-rows: $hex-height;
}
.container > div {
  width: $hex-width;
  height: $hex-height;
  margin-right: calc($hex-width / 2);
}
.container > div:nth-child(2n) {
  margin: calc((($hex-height + $gap) / 2)) ($hex-width * -0.25) 0 ($hex-width * -0.75);
}
