@import 'Styles/variables';
@import 'Styles/colors';
@import 'Styles/mixins';

.container {
  position: fixed;
  opacity: 1;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 0 0 24px;
  background-color: $bg-color-dark;
  width: 100%;
  height: 24px;
  transition: height ease 0.5s, opacity ease 0.1s;
  z-index: $z-index-logs;

  &.opened {
    height: 70%;

    &.fullScreen {
      height: calc(100% - #{$header-height});
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &.inactiveTab {
    display: none;
  }
}

.shield {
  @include shield;
  position: fixed;

  opacity: 0;
  pointer-events: none;

  transition: opacity ease 0.4s;

  &.show {
    opacity: $opacity-shield;
    pointer-events: all;
    cursor: pointer;
  }
}
