@import 'Styles/variables';

$color-bg: palette(base, 800);

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: $color-bg;
}

.modal {
  width: 95 * $grid-unit;
}

.justify {
  margin: 4 * $grid-unit 0 -$grid-unit 0;
  padding: 0 $grid-unit;

  textarea {
    min-width: 718px !important;
    max-width: 718px !important;
    max-height: 200px !important;
    min-height: 65px !important;
  }
}
