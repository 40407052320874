@import 'Styles/variables';
@import 'Styles/colors';
@import 'Styles/mixins';
@import 'Styles/borders';
@import 'Styles/shadows';

$workflowMargin: 2.5 * $grid-unit;
$container-height: 240px;
$color-border: palette(base, 500);
$color-box-bg: $bg-box;

.workflowContainer {
  @include shadow(3);
  @include border($color-border);
  border-radius: $grid-unit;
  border-left: $grid-unit solid;
  transition: border-left-color ease 0.6s;

  display: flex;
  flex-direction: column;
  background-color: $color-box-bg;
  margin: $workflowMargin $workflowMargin 0 $workflowMargin;
  padding: 2 * $grid-unit;
  height: $container-height;

  $workflow-status: (
    $status-up: $color-status-up,
    $status-down: $color-status-down,
    $status-loading: $color-status-changing,
    $status-error: $color-status-error
  );
  @each $status, $color in $workflow-status {
    &.#{$status} {
      border-left-color: $color;
    }
  }

  .chartContainer {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  .workflowHeader {
    @include font-body;
    font-weight: 500;
    color: font-color(light);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2 * $grid-unit;

    .titleName {
      @include font-subheader;
      color: font-color(lowlight);
      font-size: 13px;
    }

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .button {
      cursor: pointer;
      color: font-color(lowlight);

      &:hover svg {
        color: font-color(light);
      }
    }
  }
}
