@import 'Styles/colors';
@import 'Styles/variables';
@import 'Styles/mixins';

$color-input-bg: palette(base, 600);

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: palette(base, 700);
  color: font-color(lowlight);
}

.row:last-child {
  border-bottom: solid 1px palette(base, 400);
}

.row {
  @include font-body;
  line-height: 13px;

  display: flex;
  flex-direction: row;
  border-top: solid 1px palette(base, 400);
  transition: color ease 0.3s, background-color ease 0.3s;

  &:hover {
    &,
    .typeColValue {
      color: font-color(light);
    }

    input,
    textarea {
      color: font-color(light);
    }
  }

  > div {
    padding: 4 * $grid-unit 2.5 * $grid-unit 2 * $grid-unit 4 * $grid-unit;
  }

  .col1 {
    width: 20%;
    min-width: 140px;
    flex-shrink: 0;
  }
  .col2 {
    width: 30%;
    min-width: 170px;
    flex-shrink: 0;
  }
  .col3 {
    width: 50%;
    min-width: 240px;
  }
  .showMore {
    padding: 32px 0 0 0;

    > div {
      margin: 0 2 * $grid-unit 0 0;
    }
  }

  .typeCol {
    display: flex;
    position: relative;
    padding: 2 * $grid-unit 0;

    .typeColValue {
      padding: 0 $grid-unit 0 2 * $grid-unit;
      width: fit-content;
      z-index: 2;
    }
  }

  .variableCol {
    display: flex;
    padding: 2 * $grid-unit 0;

    > div {
      margin-left: 2 * $grid-unit;
    }
  }

  .variableValue {
    > label {
      display: none;
    }

    > div {
      top: 13px;
    }
  }
}

.header {
  height: 3 * $grid-unit;
  border-top-width: 0;
  border-bottom: 1px solid palette(base, 300);
  pointer-events: none;

  > div {
    @include font-small;
    color: font-color(lowlight);
    line-height: 3 * $grid-unit;

    padding: 0 0 0 4 * $grid-unit;
    background-color: palette(base, 400);
  }

  > div:not(:last-child) {
    border-right: 1px solid palette(base, 300);
  }
}

.list {
  overflow: auto;
}
