@import 'Styles/colors';
@import 'Styles/mixins';
@import 'Styles/shadows';

.show {
  .container {
    width: 50%;
  }
  .bg {
    @include shield;
    z-index: 8;
    opacity: 0;
  }
}

.bg {
  opacity: 0;
  background-color: transparent;
  transition: opacity ease 0.3s, background-color ease 0.3s;
}

.container {
  @include shadow(2);

  position: absolute;
  right: 0;
  top: 0;
  height: calc(100% - 40px);
  background-color: $color-black;
  width: 0;
  transition: width ease 0.5s;
  z-index: 9;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header,
  .title,
  .varType,
  .varKey {
    display: flex;
    height: 5 * $grid-unit;
    align-items: center;
  }

  .head {
    padding-left: 4 * $grid-unit;

    .header {
      @include font-body;
      color: font-color(light);

      margin-bottom: $grid-unit;
      justify-content: space-between;

      .title {
        margin-top: $grid-unit;
      }
    }
    .varType,
    .varKey {
      @include font-body;
      color: font-color(lowlight);

      padding-left: $grid-unit;

      > svg {
        margin-right: $grid-unit;
      }
    }
    .varKey {
      margin-bottom: 2 * $grid-unit;
    }
  }

  .body {
    background-color: palette(base, 600);
    flex-grow: 1;
    padding: 0 2 * $grid-unit 5 * $grid-unit 5 * $grid-unit;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .title {
      @include font-caption;
      color: font-color(regular);

      flex-shrink: 0;
    }
    .varValue {
      @include font-code;
      color: font-color(lowlight);

      overflow: auto;
    }
  }
}
