@import '~kwc/styles/borders';
@import '~kwc/styles/mixins';

@mixin hexagonWrapper {
  text-align: center;
  position: relative;
}

@mixin hexagon {
  height: 100%;
  width: calc(100% * 0.57735);
  display: inline-block;

  &:before {
    position: absolute;
    top: 0;
    right: calc((100% / 2) - ((100% * 0.57735) / 2));
    background-color: inherit;
    height: inherit;
    width: inherit;
    content: '';
    transform: rotateZ(60deg);
  }

  &:after {
    position: absolute;
    top: 0;
    right: calc((100% / 2) - ((100% * 0.57735) / 2));
    background-color: inherit;
    height: inherit;
    width: inherit;
    content: '';
    transform: rotateZ(-60deg);
  }
}
