@import 'Styles/colors';
@import 'Styles/mixins';

$color-hexagon: palette(base, 400);
$color-hexagon-active: $color-primary;
$color-hexagon-hover: palette(highlight, 700);
$color-hexagon-disabled: palette(base, 200);

.container {
  padding-top: 16px;
  z-index: 2;

  .link:last-child:after {
    background-color: transparent;
  }
}

.link {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 40px;
    background-color: $color-hexagon;
    transition: background-color ease 0.4s;
  }
}

.buttonContainer {
  @include hexagonWrapper;

  position: absolute;
  left: 15px;

  width: 40px;
  height: 40px;
}

.button {
  @include font-button;
  color: font-color(regular);

  @include hexagon;

  background-color: $color-hexagon;
  transition: background-color ease 0.2s, box-shadow ease 0.2s;
}

.active {
  &:after {
    background-color: $color-hexagon-active;
  }

  .button {
    background-color: $color-hexagon-active;
    box-shadow: -26px -2px 29px -2px rgba($color-hexagon-active, 0.4);
  }

  .buttonText {
    color: font-color(dark);
  }
}

.buttonText {
  @include center;

  @include font-button;
  color: font-color(light);

  transition: color ease 0.3s;

  pointer-events: none;
}

.miniHex {
  .buttonWrapper,
  .buttonContainer {
    height: 14px;
    width: 14px;
  }
}

.buttonWrapper {
  height: 40px;
  width: 40px;
  margin: 0 30px 24px 0;

  &:hover {
    .button {
      background-color: $color-hexagon-hover;
      box-shadow: -26px -2px 29px -2px rgba($color-hexagon-active, 0.3);
    }
    .buttonText {
      color: font-color(dark);
    }
  }
  &.disabled {
    .button {
      background-color: $color-hexagon-disabled;
      cursor: not-allowed;
    }
    .buttonText {
      color: font-color(dark);
    }
    &:hover {
      .button {
        box-shadow: unset;
      }
    }
  }

  .miniHex:nth-child(1) .buttonContainer {
    left: 17px;
    top: 0px;
  }
  .miniHex:nth-child(2) .buttonContainer {
    left: 31px;
    top: 8px;
  }
  .miniHex:nth-child(3) .buttonContainer {
    left: 17px;
    top: 16px;
  }
  .miniHex:nth-child(4) .buttonContainer {
    left: 31px;
    top: 24px;
  }
}

.addButton {
  .buttonWrapper:first-child {
    pointer-events: none;
  }

  .buttonWrapper:last-child {
    position: absolute;
    top: 2px;
    left: 2px;

    &,
    .buttonContainer {
      height: 36px;
      width: 36px;
    }

    .button {
      background-color: $bg-color-dark;
    }

    &:hover {
      .button {
        background-color: #38a7c1;
      }
    }

    .buttonText {
      font-size: 16px;
    }
  }
}
