@import '~kwc/styles/app.global.scss';

@import './colors';
@import './variables';

#chartjs-tooltip {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  background-color: palette(base, 800);
  border-radius: calc($grid-unit / 2);
  width: fit-content;
  height: fit-content;

  transition: top ease 0.3s, left ease 0.3s, opacity ease 0.3s;
}
