@import './colors';
@import './mixins';
@import './variables';

$base-left-padding: 3 * $grid-unit;
$increment-left-padding: 2 * $grid-unit;

.markdown-navigation {
  padding-top: 4 * $grid-unit;

  div {
    @include font-button;
    font-weight: 800 !important;
    color: font-color(lowlight) !important;
    text-transform: uppercase;
  }

  .title-level2 {
    padding-left: $base-left-padding;
    font-size: 13px !important;
  }
  .title-level3 {
    padding-left: $base-left-padding + $increment-left-padding;
    font-size: 11px !important;
  }
  .title-level4 {
    padding-left: $base-left-padding + 2 * $increment-left-padding;
    font-size: 10px !important;
  }
  .title-level5 {
    padding-left: $base-left-padding + 3 * $increment-left-padding;
    font-size: 9px !important;
  }

  .title-anchor {
    transition: color ease 0.4s, background-color ease 0.4s;
  }
  .title-anchor.active {
    color: font-color(light) !important;
    font-weight: 800 !important;
  }

  .title-anchor:hover,
  .title-anchor.active {
    background-color: transparent;
  }

  .title-level2:hover,
  .title-anchor:hover {
    background-color: $bg-highlighted;
    color: font-color(light) !important;
  }
}
