@import 'Styles/colors';
@import 'Styles/mixins';

$color-bg: palette(base, 800);
$color-bg-header: palette(base, 600);

.container {
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: $color-bg;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 540px;
  overflow: hidden;
}
.generalSettings {
  .formField {
    height: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .label {
      @include font-body;
      color: font-color(light);

      margin: 0 40px 0 30px;
    }

    svg {
      margin-left: 20px;
    }

    .input {
      width: 140px;
    }
  }
}

.securitySettings {
  .tabs {
    background-color: $color-bg-header;
  }
  background-color: $color-bg;
}

.header {
  padding: 3 * $grid-unit;
  background-color: $color-bg-header;
}

.formTitle {
  @include font-headline;
  color: font-color(lowlight);
}

.formDescription {
  @include font-body;

  margin-top: 10px;
}

.listsContainer {
  display: flex;
  overflow: hidden;
  height: 100vh;
  & > div:first-child {
    margin-right: 48px;
  }
}

@media (max-width: 1400px) {
  .listsContainer {
    flex-direction: column;
    & div:first-child {
      margin-right: 0;
    }
  }
}
