@import 'Styles/colors';
@import 'Styles/mixins';

:export {
  matrix10: #00252e;
  matrix20: #00303b;
  matrix30: #004151;
  matrix40: #005266;
  matrix60: palette(highlight, 400);
  matrix80: palette(highlight, 200);
  matrix90: palette(highlight, 100);
  matrix100: #d6ffff;

  textDark: #00252e;
  textLight: #ccf5ff;
}

$color-axisCell: map-get($color-dashboard, primary-dark);
$color-primaryLight: map-get($color-dashboard, primary-light);

text {
  @include font-caption;
  color: font-color(lowlight);
}

.cellText {
  font-weight: 600;
  dominant-baseline: central;
  pointer-events: none;
  font-size: 14px;
  font-weight: bold;
}

.axisBoxes {
  rect {
    fill: $color-axisCell;
    opacity: 0.1;
  }

  text {
    fill: $color-primaryLight;
    text-anchor: middle;
    dominant-baseline: central;
  }
}

.legendRect {
  rx: 5px;
}

.legendG {
  line {
    stroke: $bg-color;
    opacity: 0.4;
  }
  text {
    transform: translateX(8px);
  }
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:first-child {
    margin-bottom: 8px;
  }
}
