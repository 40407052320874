@import 'Styles/colors';
@import 'Styles/mixins';
@import 'Styles/borders';
@import 'Styles/shadows';

$color-bg: palette(base);

.container {
  @include font-button;
  position: relative;
  line-height: 40px;
  height: 40px;
  text-align: center;
  padding: 0 12px;
  cursor: pointer;
  transition: color ease 0.4s;
  color: font-color(light);
  z-index: 2;

  &:hover {
    color: font-color(light);

    .arrow {
      border-color: white;
    }
  }

  &:active {
    box-shadow: none;
  }

  &:after {
    @include arrow(white);

    position: absolute;
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
    right: 14px;
    top: 14px;
    content: '';
    transition: transform ease 0.3s, top ease 0.3s;
  }

  &.is-open:after {
    transform: rotate(45deg) scale(-1);
    top: 17px;
  }
}

.label {
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  user-select: none;
}

.options {
  @include shadow(4);

  transition: max-height ease 0.3s;
  overflow: hidden;
  background-color: $color-bg;
}
