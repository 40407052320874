@import 'Styles/variables';
@import 'Styles/colors';

.container {
  color: font-color(lowlight);
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 700px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
}

.spinnerUpdating {
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  height: 80%;
}

.noConfig {
  color: font-color(light);
  font-weight: 500;

  margin: 50px 0 0 20px;
}

.filters {
  height: 11 * $grid-unit;
  padding: 0 3 * $grid-unit;
  background-color: palette(lowlight, 900);
  display: flex;
  align-items: center;

  .filterSearch {
    width: 40%;
    max-width: 50 * $grid-unit;
  }
  .filterType {
    margin-left: $grid-unit;
    width: 133px;
  }
  .button {
    border-left: solid 1px palette(base, 400);
    margin-left: $grid-unit;
  }
}

.bottomBar {
  z-index: 10;
}
